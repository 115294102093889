import { React, useState } from 'react';
import { Form, Button, Modal, Container, FormGroup } from 'react-bootstrap';
import config from '../../Constants';
import Events from "../../Activity/Events";
function CreateEvent(token) {
    //var token = JSON.parse(localStorage.getItem("@@auth0spajs@@::jfxaDoS4vEnCAlbbct8EIQUlCjCNC64s::@@user@@")); 
    var myauth = `Bearer ${token}`;
    const [EventDT, setEventDT] = useState('');
    const [EventName, setEventName] = useState('');
    const [EventDescription, setEventDescription] = useState('');
    const [EventType, setEventType] = useState('');
    const [Oganizer, setOganizer] = useState('');
    const [CreatedDT, setCreatedDT] = useState('');
    const [showModal, setShowModal] = useState(false);

    const resetForm = () => {
        setEventDT('');
        setEventName('');
        setEventDescription('');
        setEventType('');
        setOganizer('');
        setCreatedDT('');
    };


    const handleSubmit = (e) => {
        e.preventDefault();     
        
  
        const requestOptions = {
      method:'POST',
      headers: {'Content-Type': 'application/json','Authorization':`${myauth}`},
      body: JSON.stringify({
        EventDT, EventName, EventDescription, EventType, Oganizer, CreatedDT
      }),
         
    }

    fetch(`${config.url}/DeemiEvents`, requestOptions)       
    .then((res) => res.json())  
    .then((data) => console.log(data))
        handleCloseModal();
}

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    return (
   
        <Container>
            <Button variant="primary" onClick={handleShowModal}>Create Event</Button>
            <br/>
            <br/>
             {Events(true)}
        
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Create Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="eventName">
                        <Form.Label>Event Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={EventName}
                            onChange={(e) => setEventName(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="eventDescription">
                        <Form.Label>Event Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={EventDescription}
                            onChange={(e) => setEventDescription(e.target.value)}
                        />
                    </Form.Group>

                    <FormGroup>
                        <Form.Label>Event Type</Form.Label>
                        <Form.Control
                            as="select"
                            value={EventType}
                            onChange={(e) => setEventType(e.target.value)}
                        >
                            <option value="1">Event Type 1</option>
                            <option value="2">Event Type 2</option>
                            <option value="3">Event Type 3</option>
                        </Form.Control>
                    </FormGroup>

                    <Form.Group controlId="eventDT">
                        <Form.Label>Event Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={EventDT}
                            onChange={(e) => setEventDT(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="createdDT">
                        <Form.Label>Created Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={CreatedDT}
                            onChange={(e) => setCreatedDT(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="oganizer">
                        <Form.Label>Created By</Form.Label>
                        <Form.Control
                            type="text"
                            value={Oganizer}
                            onChange={(e) => setOganizer(e.target.value)}
                        />
                    </Form.Group>
                    <br/>
                    <br/>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
        </Container>
    );
}

export default CreateEvent;
