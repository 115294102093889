
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/esm/Container";
import Card from "react-bootstrap/Card";

function Board() {

      return (
        <>
       
        <Container>
          <Row>
            <Col>           
                <Card style={{ width: '18rem' }}>
                  <Card.Img variant="top" src="/img/Headshots/VinalApplebee.jpg" />
                  <Card.Body>
                    <Card.Title>Vinal Applebee</Card.Title>
                    <Card.Text>
                      UAV section chief
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
            <Col>           
                <Card style={{ width: '18rem'}}>
                  <Card.Img variant="top" src="" />
                  <Card.Body>
                    <Card.Title>Al Rogers </Card.Title>
                    <Card.Text>
                      Chief pilot
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
            <Col>           
                <Card style={{ width: '18rem' }}>
                  <Card.Img variant="top" src="/img/Headshots/ChrisRowley.jpg" />
                  <Card.Body>
                    <Card.Title>Chris Rowley</Card.Title>
                    <Card.Text>
                        Imagery analysis section chief
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
          </Row>
          <Row>
            <br/>
          </Row>
          <Row>
          <Col>           
                <Card style={{ width: '18rem' }}>
                  <Card.Img variant="top" src="/img/Headshots/DanWarner.png" />
                  <Card.Body>
                    <Card.Title>Dan Warner</Card.Title>
                    <Card.Text>
                      HMMWV section chief
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
            <Col>           
                <Card style={{ width: '18rem' }}>
                  <Card.Img variant="top" src="/img/Headshots/SimonLowther.jpg" />
                  <Card.Body>
                    <Card.Title>Simon Lowther</Card.Title>
                    <Card.Text>
                      Special Projects
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
            <Col></Col>
          </Row>
          
        </Container>
        </>
      );
    }
    



export default Board;