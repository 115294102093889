import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Portalnav from "./Portal/PortalNav"
//import { useKeycloak } from "@react-keycloak/web";
import {  NavLink } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
//import Portal from './Portal/Portal';


function Navigation() {
  //const { keycloak, initialized } = useKeycloak();
  //if(keycloak.authenticated)
  //{
 //   localStorage.setItem("access_token", JSON.stringify(keycloak.token));
 // }
 const [isOpen, setIsOpen] = useState(false);
 const {
   user,
   isAuthenticated,
   loginWithRedirect,
   logout,
 } = useAuth0();
 const toggle = () => setIsOpen(!isOpen);

 const logoutWithRedirect = () =>
   logout({
       logoutParams: {
         returnTo: window.location.origin,
       }
   });
    return (
    <>
    <Navbar expand="lg" className="bg-body-tertiary">
    <Container>
    <Navbar.Brand href="Home">
          <img
            alt=""
            src="/img/deemilogo.jpg"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          DEEMI
        </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="/Home">Home</Nav.Link>
          <Nav.Link href="/Activity">Activity</Nav.Link>         
          <Nav.Link href="/Members">Members</Nav.Link>
          <Nav.Link href="/Volunteer">Volunteer</Nav.Link>
          <Nav.Link href="/MotorPool">Motor Pool</Nav.Link>     
          <Nav.Link href="/ContactUs">Contact Us</Nav.Link>     
        </Nav>
        <Nav>
          
        {Portalnav(isAuthenticated)}
        
        {!isAuthenticated  && (
          <>
                   
                  <NavLink
                                   
                     onClick={() => loginWithRedirect()}
                     
                   >
                     Login
                   </NavLink>
                   </>
                 )}
          
            {isAuthenticated &&(
                    <>
                     
                   <NavLink
                    
                     onClick={() => logoutWithRedirect()}
                   >
                     Logout 
                   </NavLink>
                   </>
)}
          </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  </>
    )
}

export default Navigation;
