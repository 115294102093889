import Keycloak from 'keycloak-js'

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
    url: "https://kc.yourtools.us",
    realm: "deemi",
    clientId: "deemi-react",
    publicClient: true,
    sslRequired: "external",
    authServerUrl: "https://kc.yourtools.us/realms/deemi/",
    realmPublicKey: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAv4f35MqxBobIm2eGlY2eL6A7TP/qLIKcKjIBsZj3kxLPKK2qDgAgxRwqYTBiOE7wsNosxY6/2Qup3qsD22IOrj9OGO6e1QojMI9/hLTfpcLafjifQFMCkW9VSmYxgFjjZc3r0Qh5ROem7/5LuOafPYcX5ZJhDwDYd9lCrbuatupNs17NO3AaYmAmsSBoJi8OweFL7GFPKG9FVEn4/6+Qr07FA8sh2OLukTel7EhEfArfJYMmIBZN4IcluB35JN7AA3wkawstb7InNvUcZl1IdkQEkw+FhPK7GjkcEib9WSPLIUufAYxxp52FG3H41H63uRJT9BHH2RPkLyT+qMGcMwIDAQAB"
    
  });

export default keycloak