import React, { useEffect, useState } from 'react';
import ListGroup from "react-bootstrap/ListGroup"
import Card  from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import config from '../Constants'
import { NavLink } from 'react-bootstrap';
function Events(portal) {
    var myauth = 'Bearer ' + JSON.parse(localStorage.getItem("access_token")); 
    const [data, setData] = useState([]);
    GetEvent();
     function  GetEvent(){  
        useEffect(() => {     
        let myurl = `${config.url}/DeemiEvents`;      
        var myHeaders =  new Headers();       
         fetch(myurl, {     
          method: 'GET',
          headers: myHeaders          
          })
          .then((res) => res.json())
          .then((data) => setData(data)) 
      } ,[]);}
    function handleDelete(id){
      fetch(`${config.url}/DeemiEvents/${id}`, {
        method: 'DELETE',
        
        headers: {
          'Content-Type': 'application/json', 'Authorization': `${myauth}`
        },
        body: JSON.stringify({id: id})
      })
      

      }

      

      var messages =  data.map(mess => {
        var DeleteButton = (portal) ?<Card.Footer> <NavLink variant="danger" onClick={() => handleDelete(mess.id)}>Delete</NavLink> </Card.Footer> : null;

      return(
        <>
        <Card key={mess.id} >                
        <Card.Body>
            <Card.Title>{mess.eventName}</Card.Title>
            
            <Card.Text>
                {mess.eventDescription}
            </Card.Text>
        </Card.Body>        
        <ListGroup className="list-group-flush">
            <ListGroup.Item >Event Type : {mess.eventType}</ListGroup.Item>
            <ListGroup.Item>Event Date Time : {mess.eventDT}</ListGroup.Item>       
            <ListGroup.Item>Oganizer : {mess.oganizer}</ListGroup.Item>  
        </ListGroup>
        
            {DeleteButton}
        
       
        </Card>
        <br/>
        </>

        );})

        var nonews = <Card  ><Card.Body><Card.Title>No Events</Card.Title></Card.Body> </Card>
      

    return(
    <>
    
    {data.length > 0 ?  messages : nonews }
        
     
    </>
    );
}


export default Events;