import Navigation from "./Navigation"
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/esm/Container";
import config from './Constants'
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";


var UsaStates = require('usa-states').UsaStates;
function Volunteer() {
  var usStates = new UsaStates();
  var statesAbbreviation = usStates.arrayOf('abbreviations');
  const [FirstName, setFirst] = useState("");
  const [LastName, setLast] = useState("");
  const [Email, setEmail] = useState("");
  const [ContactNumber, setContactnumber] = useState("");
  const [Address1, setAddress1] = useState("");
  const [Address2, setAddress2] = useState("");
  const [City, setCity] = useState("");
  const [Usstate, setState] = useState("ME");
  const [Zip, setZip] = useState(""); 
  const RestForm = () => {
    setFirst("")
    setLast("")
    setEmail("")
    setContactnumber("")
    setAddress1("")
    setAddress2("")
    setCity("")
    setState("")
    setZip()
  }
  const handleSubmit = (event) => {
    event.preventDefault();
        const requestOptions = {
      method:'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        FirstName, LastName, Email, ContactNumber, Address1, Address2, City, Usstate, Zip
      }),
      
    };    
   
    fetch(`${config.url}/Volunteers`, requestOptions)       
        .then((res) => {
          if(res.status > 300)
          {
            alert('Please check your fields');
            
          }
          else
          {           
            alert('Your Message has been successfully sent, a response will be sent to the email provided')
            RestForm()
          }
        }) 
  
  };
  return (
    
    <>
      {Navigation()}
      <Container>
        <Row>
          <Col md="auto">
            <img alt="" src='/img/deemilogo.jpg'></img>
          </Col>
          <Col>
            <h1>DOWN EAST EMERGENCY MEDICINE INSTITUTE</h1>
            <h3>The mission of DEEMI is to develop with ethics and integrity, systems that will save lives.
              Through education and training, and using Federal surplus military equipment, to encourage the spirit of volunteerism, and to facilitate the saving of lives in Maine and New England
            </h3>
          </Col>

        </Row>
        <Row>
          <br />
        </Row>
        <Row>
          <h4>
            DEEMI does not share any personal information collected. all information collected is only used to contact indidviduals inline with DEEMI's mission statement
          </h4>
        </Row>
        <Row>
          <br />
        </Row>
        <Row  >
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridFirst">
                <Form.Label>First Name</Form.Label>
                <Form.Control maxLength={50} type="text" placeholder="First Name" required  onChange={(e) => setFirst(e.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridLast">
                <Form.Label>Last Name</Form.Label>
                <Form.Control  maxLength={50} type="text" placeholder="Last Name" required  onChange={(e) => setLast(e.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control  maxLength={150} placeholder="Enter email" required  onChange={(e) => setEmail(e.target.value)} />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPhone">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control maxLength={11} type="phonenumber" placeholder="Contact Number" required  onChange={(e) => setContactnumber(e.target.value)} />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Address</Form.Label>
              <Form.Control placeholder="1234 Main St" required  onChange={(e) => setAddress1(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress2">
              <Form.Label>Address 2</Form.Label>
              <Form.Control placeholder="Apartment, studio, or floor" onChange={(e) => setAddress2(e.target.value)} />
            </Form.Group>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>City</Form.Label>
                <Form.Control required  onChange={(e) => setCity(e.target.value)} />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>State</Form.Label>
                <Form.Select defaultValue="ME" onChange={(e) => setState(e.target.value)}>
                  {statesAbbreviation.map((e) => <option value={e} key={e}>{e}</option>)}
                </Form.Select>
             
              </Form.Group>

              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label>Zip</Form.Label>
                <Form.Control onChange={(e) => setZip(e.target.value)} required maxLength={5}/>
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" id="formGridCheckbox">
              <Form.Check required type="checkbox" label="I agree to be contacted by DEEMI"    feedback="You must agree before submitting."  feedbackType="invalid"/>
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Row>
      </Container>
    </>
  );
}




export default Volunteer;