import { useState } from 'react';

import Offcanvas from 'react-bootstrap/Offcanvas';

import { Nav, NavLink } from 'react-bootstrap';
function Example(authenticated) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
   if(authenticated)

   {
  return (
    
      <>
        <NavLink variant="primary" onClick={handleShow}>
          Portal
        </NavLink>
  
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Portal</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-column">
            <Nav.Link href="/Portal">Portal</Nav.Link>              
            <Nav.Link href="/Portal/Admin">Admin</Nav.Link>
             
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
    </>
  );
   }
   else
   {
    return <></>
   }
}

export default Example;