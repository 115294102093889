import React from "react";
import { Container, FormGroup, FormLabel } from "react-bootstrap";
import Searches from "../../Activity/Searches";
import { Button, Modal, Form, FormControl } from "react-bootstrap";
import { useState } from "react";
import config from "../../Constants";

function Search(token) {
  //var token = JSON.parse(localStorage.getItem("@@auth0spajs@@::jfxaDoS4vEnCAlbbct8EIQUlCjCNC64s::@@user@@"));
  var myauth = `Bearer ${token}`;
  const [InitalContactTime, setInitalContactTime] = useState("");
  const [InitalContactDate, setInitalContactDate] = useState("");
  const [ContactID, setContactID] = useState("");
  const [ContactatSite, setContactatSite] = useState("");
  const [MissionRequest, setMissionRequest] = useState("");
  const [MissionType, setMissionType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const resetForm = () => {
    setInitalContactTime("");
    setInitalContactDate("");
    setContactID("");
    setContactatSite("");
    setMissionRequest("");
    setMissionType("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${myauth}`,
      },
      body: JSON.stringify({
        InitalContactTime,
        InitalContactDate,
        ContactID,
        ContactatSite,
        MissionRequest,
        MissionType,

      }),
    };
   
    fetch(`${config.url}/DeemiEvents`, requestOptions)
      .then((res) => res.json())
      .then((data) => console.log(data));
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <Container>
      <Button variant="primary" onClick={handleShowModal}>
        Create Search
      </Button>
      <br />
      <br />
      {Searches(true)}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Start Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>Inital Contact Time</FormLabel>
              <FormControl
                type="time"
                placeholder="Inital Contact Time"
                value={InitalContactTime}
                onChange={(e) => setInitalContactTime(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Inital Contact Date</FormLabel>
              <FormControl
                type="date"
                placeholder="Inital Contact Date"
                value={InitalContactDate}
                onChange={(e) => setInitalContactDate(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Contact ID</FormLabel>
              <FormControl
                type="text"
                placeholder="Contact ID"
                value={ContactID}
                onChange={(e) => setContactID(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Contact at Site</FormLabel>
              <FormControl
                type="text"
                placeholder="Contact at Site"
                value={ContactatSite}
                onChange={(e) => setContactatSite(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Mission Request</FormLabel>
              <FormControl
                type="text"
                placeholder="Mission Request"
                value={MissionRequest}
                onChange={(e) => setMissionRequest(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Mission Type</FormLabel>
              <FormControl
                type="text"
                placeholder="Mission Type"
                value={MissionType}
                onChange={(e) => setMissionType(e.target.value)}
              />
            </FormGroup>

            <br />
            <br />
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default Search;
