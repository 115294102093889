
import React, { useEffect, useState } from 'react';
import Container from "react-bootstrap/Container";
import Col from 'react-bootstrap/Col';
import Card from "react-bootstrap/Card";
import config from "../../Constants"
import    Row  from 'react-bootstrap/Row';
import ListGroup from "react-bootstrap/ListGroup"
import Button from 'react-bootstrap/Button';

function ContactMessages(token) {
 // var token = JSON.parse(localStorage.getItem("@@auth0spajs@@::jfxaDoS4vEnCAlbbct8EIQUlCjCNC64s::@@user@@")); 
  var myauth = `Bearer ${token}`;
  const [show, setShow] = useState(false);
    ContactMessages();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
  const [data, setData] = useState([]);
  async function handleDelete(id){
  
    fetch(`${config.url}/ContactMessages/${id}`, {
      method: 'DELETE',
      
      headers: {
        'Content-Type': 'application/json', 'Authorization': `${myauth}`
      },
      body: JSON.stringify({id: id})
    })
    }
    
   async function ContactMessages(){   
    useEffect(() => {
    let myurl = `${config.url}/ContactMessages`;
    var myauth = `Bearer ${token}`;    
    var myHeaders =  new Headers();
   
    myHeaders.append('Authorization', `${myauth}`);
     fetch(myurl, {     
      method: 'GET',
      headers: myHeaders          
      })
      .then((res) => res.json())
      .then((data) => setData(data)) 
    }, []);}
  
  

var messages = data.map(mess =>
      
    <Card key={mess.id} >                
    <Card.Body>
        <Card.Title>From : {mess.email}</Card.Title>
        <Card.Subtitle>Message</Card.Subtitle>
        <Card.Text>
            {mess.message}
        </Card.Text>
    </Card.Body>
    <ListGroup className="list-group-flush">
        <ListGroup.Item >ID : {mess.id}</ListGroup.Item>
        <ListGroup.Item>Created : {mess.created}</ListGroup.Item>
        <ListGroup.Item>Active : {mess.active}</ListGroup.Item>
    </ListGroup>
    <Card.Footer>
        <Button variant="danger" onClick={() => handleDelete(mess.id)}>Delete</Button>
    </Card.Footer>
    </Card>
    );
  return (
    <>
    
 
      
      <Container>

        
        <Row>
        <Col xs lg="2">
        </Col>
        <Col>
          <h1>Messages</h1>
        </Col>
        <Col />
        <Col xs lg="3">
        
        </Col>
        </Row>
        <Row>
       
        </Row>
        <Row>     {data != null ?  messages : "" }
        </Row>
      </Container>
      
  
      

    </>
  );
}
export default ContactMessages;