import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home";
import Members from "./Components/Memebers/Members";
import Activity from "./Components/Activity/Activity";
import Volunteer from "./Components/Volunteer";
import MotorPool from "./Components/MotorPool/MotorPool";
import ContactUs from "./Components/ContactUs";
import Portal from "./Components/Portal/Portal";

import CreateActivity from "./Components/Portal/CreateActivity";
//import Loading from "./Components/Loading";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
// import { useKeycloak } from "@react-keycloak/web";

export default function App() {
  
 
  //const { keycloak, initialized } = useKeycloak();

  // const ProtectedRoute = ({ children }) => {
  //   if (!initialized) {
  //     return <div>Loading...</div>;
  //   }

  //   if (!keycloak.authenticated) {
  //     return <Home />;
  //   }
  //   return children;
  // };
  return (
    <Auth0Provider
    domain="dev-4lrfjaj1k5hkjenw.us.auth0.com"
    clientId="jfxaDoS4vEnCAlbbct8EIQUlCjCNC64s"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://dev-4lrfjaj1k5hkjenw.us.auth0.com/api/v2/",
      scope: "profile email offline_access"
    }}
    useRefreshTokens={true}
    cacheLocation="localstorage"
    useRefreshTokensFallback ={true}
  >
    <div>
      
      <BrowserRouter>
        <Routes>
          <Route path="Home" element={<Home />} />
          <Route path="MotorPool" element={<MotorPool />} />
          <Route path="Members" element={<Members />} />
          <Route path="Volunteer" element={<Volunteer />} />
          <Route path="ContactUS" element={<ContactUs />} />
          <Route path="Activity" element={<Activity />} />
          <Route
            path="Portal"
            element={
             
                
                <Portal />
          
            }
          />
         
             <Route
            path="Portal/Admin"
            element={
           
                <CreateActivity />
          
            }
          />
            <Route path='*' element={<Home />}/>
          </Routes>
        
          
      </BrowserRouter>
    </div>
    </Auth0Provider>
  );
}
