import Navigation from "../Navigation"
import React, {useEffect, useState} from 'react';

import Container from "react-bootstrap/esm/Container";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CreateNews from './Activity/CreateNews';
import CreateEvent from "./Activity/GreateEvent";
import Search from "./Activity/Search";
import ContactMessages from "./Activity/ContactMessages";
import NewVolunteers from "./Activity/NewVolunters";
import { json } from "react-router-dom";
import config from "../Constants"


function CreateActivity() {
  var token = JSON.parse(localStorage.getItem("@@auth0spajs@@::jfxaDoS4vEnCAlbbct8EIQUlCjCNC64s::@@user@@")); 

  const { isLoading, error } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    const getUserMetadata = async () => {
  try
  {
  const accessToken = await getAccessTokenSilently({
    authorizationParams: {
      scope: "read:current_user",
      audience: config.audience, //"https://dev-4lrfjaj1k5hkjenw.us.auth0.com/api/v2/",
      client_id: config.clientId,//"jfxaDoS4vEnCAlbbct8EIQUlCjCNC64s",
      redirect_uri: window.location.origin,
      domain: config.domain //"dev-4lrfjaj1k5hkjenw.us.auth0.com",
    },
  });
 
}
catch(e)
{
  console.log(e.message);
}
};
getUserMetadata();

});
  return (
    
    <>
      {Navigation()}
      
      <Container>
      <Tabs
      defaultActiveKey="News"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey={"News"} title="News" key={"News"}>
        {CreateNews(token.id_token)
        }
      </Tab>
      <Tab eventKey={"Searches"} title="Searches" key={"Searches"}>
      {
        Search(token.id_token) 
      }
      </Tab>
      <Tab eventKey={"Events"} title="Events" key={"Events"}>
        {
        CreateEvent(token.id_token)
        }
      </Tab>
      <Tab eventKey={"ContactMessages"} title="Contact Messages" key={"Messages"}>
        {ContactMessages(token.id_token)
        }
      </Tab>
      <Tab eventKey={"NewVolunters"} title="New Volunters" key={"Volunters"}>
        {
       NewVolunteers(token.id_token)
      }
      </Tab>
    </Tabs>
        
        
      </Container>
    </>
  );
}




export default withAuthenticationRequired(CreateActivity);