import Navigation from "../Navigation"
import React from 'react';
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup"
import config from "../Constants"
import { useState, useEffect } from 'react';
import { NavLink } from "react-bootstrap";
function MotorPool() {
  const [data, setData] = useState([]);
  GetNews();
   function  GetNews(){  
      useEffect(() => {     
      let myurl = `${config.url}/MotorPoolView`;      
      var myHeaders =  new Headers();       
       fetch(myurl, {     
        method: 'GET',
        headers: myHeaders          
        })
        .then((res) => res.json())
        .then((data) => setData(data)) 
       
    } ,[]);}
  
console.log(data);
  
    var messages =  data.map(mess => {
      const imageUrl = `data:image/jpeg;base64, ${mess.content}`;
     
    return(
     
      <Col>
      <Card key={mess.id}  style={{ width: '18rem' }} >                
      <Card.Body>
          <Card.Title>{mess.model}</Card.Title>
          <Card.Subtitle>{mess.make}</Card.Subtitle>
          <Card.Img src={imageUrl}/>
          <Card.Text>
              {mess.newsText}
          </Card.Text>
      </Card.Body>        
      <ListGroup className="list-group-flush">
          <ListGroup.Item >Call Sign : {mess.callsign}</ListGroup.Item>
          <ListGroup.Item><NavLink href={mess.wikiLink}>Wiki Link</NavLink></ListGroup.Item>       
      </ListGroup>
     
      </Card>
      </Col>
    
    

      );})

      var nonews = <Card  ><Card.Body><Card.Title>No Active Vehicles</Card.Title></Card.Body> </Card>

  return (
    <>
      {Navigation()}
      <Container>
       <Row>
      {data.length > 0 ?  messages : nonews }
      </Row>
      </Container>
    </>
  );
}




export default MotorPool;