// Constants.js
function config(){
    let url;
    let domain;
    let clientId;
    let audience;
    switch(process.env.NODE_ENV) {
      case 'production':
        url = 'https://deemi.org/api';
        domain = 'deemi.us.auth0.com';
        clientId = "tAZbjp6N6AT0kloarfRfW7GZp8YQWuVr";
        audience = "tAZbjp6N6AT0kloarfRfW7GZp8YQWuVr/api/v2/";
        
        break;
      case 'development':
      default:
        url = 'https://localhost:32778/api';
        domain = 'dev-4lrfjaj1k5hkjenw.us.auth0.com';
    clientId = "jfxaDoS4vEnCAlbbct8EIQUlCjCNC64s";
    audience = "https://dev-4lrfjaj1k5hkjenw.us.auth0.com/api/v2/";
    }
    return {
      url,
      domain,
      clientId,
      audience
    }
}


export default config()

