import { React, useState } from 'react';
import { Form, Button, Modal, Container } from 'react-bootstrap';
import config from '../../Constants';
import Navigation from '../../Navigation';
import News from '../../Activity/News';
function CreateNews(token) {
    //var token = JSON.parse(localStorage.getItem("@@auth0spajs@@::jfxaDoS4vEnCAlbbct8EIQUlCjCNC64s::@@user@@")); 
    var myauth = `Bearer ${token}`;

    const [NewsTitle, setTitle] = useState('');
    const [NewsText, setContent] = useState('');
    const [ImageLocation, setImage] = useState('');
    const [CreatedDT, setDate] = useState('');
    const [CreatedBy, setAuthor] = useState('');
    const [showModal, setShowModal] = useState(false);
    const resetForm = () => {
        setTitle('');
        setContent('');
        setImage('');
        setDate('');
        setAuthor('');
    };


 
    const handleSubmit = (e) => {
        e.preventDefault();     
        
  
        const requestOptions = {
      method:'POST',
      headers: {'Content-Type': 'application/json','Authorization':`${myauth}`},
      body: JSON.stringify({
        NewsTitle, NewsText, ImageLocation, CreatedDT, CreatedBy
      }),
         
    }
    fetch(`${config.url}/DeemiNews`, requestOptions)       
        .then((res) => {
          if(res.status > 300)
          {
            alert('Please check your fields');
            
          }
          else
          {           
            alert('Your Message has been successfully sent, a response will be sent to the email provided')
            resetForm()
          }
        }) 
        handleCloseModal();
}

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    return (
      
        <Container>
            
        <Button variant="primary" onClick={handleShowModal}>Create News</Button>
        <br/>
            <br/>
             {News(true, token)}
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Create News</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="newsTitle">
                        <Form.Label>News Title</Form.Label>
                        <Form.Control
                            type="text"
                            value={NewsTitle}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="newsText">
                        <Form.Label>News Text</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={NewsText}
                            onChange={(e) => setContent(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="imageUpload">
                        <Form.Label>Image Upload</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    const base64String = reader.result;
                                    setImage(base64String);
                                };
                                reader.readAsDataURL(file);
                            }}
                        />
                    </Form.Group>

                    <Form.Group controlId="createdDT">
                        <Form.Label>Created Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={CreatedDT}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="createdBy">
                        <Form.Label>Created By</Form.Label>
                        <Form.Control
                            type="text"
                            value={CreatedBy}
                            onChange={(e) => setAuthor(e.target.value)}
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
        </Container>
        
    );
}

export default CreateNews;
