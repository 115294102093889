import React from 'react';

import Card  from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { useState, useEffect } from 'react';
import config from '../Constants';

function Searches() {

    const [data, setData] = useState([]);
    GetNews();
     function  GetNews(){  
        useEffect(() => {     
        let myurl = `${config.url}/Searches`;      
        var myHeaders =  new Headers();       
         fetch(myurl, {     
          method: 'GET',
          headers: myHeaders          
          })
          .then((res) => res.json())
          .then((data) => setData(data)) 
      } ,[]);}    
      var messages =  data.map(mess => {
        
       
      return(
        <>
        <Card key={mess.IntakeID} >                
        <Card.Body>
            <Card.Title>{mess.MissionType}</Card.Title>
            
            <Card.Text>
                {mess.MissionRequest}
            </Card.Text>
        </Card.Body>        
        <ListGroup className="list-group-flush">
            <ListGroup.Item >Inital Contact Date : {mess.InitalContactTime}</ListGroup.Item>
            <ListGroup.Item>Inital Contact Date : {mess.InitalContactDate}</ListGroup.Item>       
        </ListGroup>
       
        </Card>
        <br/>
        </>

        );})

        var nonews = <Card  ><Card.Body><Card.Title>No Active Searches</Card.Title></Card.Body> </Card>
          
       
       


    return(
    <>
        {data.length > 0 ?  messages : nonews }    </>
        
    
    );

}

export default Searches;