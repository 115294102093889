import {React, useEffect, useState} from 'react';
//import Container from 'react-bootstrap/Container';
import Card  from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup"
import config from "../Constants"
import Button from 'react-bootstrap/Button';

function News(portal, token) {
   
    const [data, setData] = useState([]);
    GetNews();
     function  GetNews(){  
        useEffect(() => {     
        let myurl = `${config.url}/DeemiNews`;      
        var myHeaders =  new Headers();       
         fetch(myurl, {     
          method: 'GET',
          headers: myHeaders          
          })
          .then((res) => res.json())
          .then((data) => setData(data)) 
      } ,[]);}
        function handleDelete(id){
            var myauth = `Bearer ${token}`;
            fetch(`${config.url}/DeemiNews/${id}`, {
              method: 'DELETE',
              
              headers: {
                'Content-Type': 'application/json', 'Authorization': `${myauth}`
              },
              body: JSON.stringify({id: id})
            })
            GetNews()
            }
            var messages =  data.map(mess => {
                const imageUrl = `data:image/jpeg;base64, ${mess.imageLocation}`;
       
                function getDelete(){
                    if(portal == true)            
                    {
                    return(<Button variant="danger" onClick={() => handleDelete(mess.id)}>Delete</Button> )
                         }
                        else
                        {
                        return  (<></>)
                        }
                    }
    

    
      
       
      return(
        <>
        <Card key={mess.id} >                
        <Card.Body>
            <Card.Title>{mess.newsTitle}</Card.Title>
            <Card.Img src={imageUrl}/>
            <Card.Text>
                {mess.newsText}
            </Card.Text>
        </Card.Body>        
        <ListGroup className="list-group-flush">
            <ListGroup.Item >Author : {mess.createdBy}</ListGroup.Item>
            <ListGroup.Item>Created : {mess.createdDT}</ListGroup.Item>       
            {getDelete()}
        </ListGroup>
       
        </Card>
        <br/>
        </>

        );})

        var nonews = <Card  ><Card.Body><Card.Title>No News</Card.Title></Card.Body> </Card>
          
       
       


    return(
    <>
     
     {data.length > 0 ?  messages : nonews }
    </>
    );

}

export default News;