import Navigation from "./Navigation"

import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/esm/Container";

import Carousel from 'react-bootstrap/Carousel';


function Home() {

      return (
        <>
        {Navigation()}
        <Container>
        <Row>
            <Col md="auto">                 
            <img alt="" src='/img/deemilogo.jpg'></img>      
            </Col>
            <Col>
                <h1>DOWN EAST EMERGENCY MEDICINE INSTITUTE</h1>
                <h3>The mission of DEEMI is to develop with ethics and integrity, systems that will save lives. 
                    Through education and training, and using Federal surplus military equipment, to encourage the spirit of volunteerism, and to facilitate the saving of lives in Maine and New England
                </h3>
            </Col>
       
        </Row>
        <Row  >        
        <Carousel>
          <Carousel.Item>
           <img alt="" src= '/img/DeemiHeader.jpg'style={{ alignSelf: 'center' }}></img>
            <Carousel.Caption>
              <h3>DEEMI Air and land assests</h3>
              {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
          <img alt="" src= '/img/DeemiHmmwv.jpg' style={{ alignSelf: 'center' }}></img>
            <Carousel.Caption>
              <h3>DEEMI HMMWV waiting for Life flight of Maine</h3>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
          <img alt="" src= '/img/PlaneFlying.jpg' style={{ alignSelf: 'center' }}></img>
            <Carousel.Caption>
              <h3>Citabria Over Maine</h3>
              
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        </Row>
        </Container>
        </>
      );
    }
    



export default Home;