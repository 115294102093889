import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navigation from "./Navigation"
import React, { useState } from 'react';
import config from './Constants'
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
//import PayPal from './Paypal.jsx';

function ContactUs() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSubmit = (event) => {
    event.preventDefault();
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email, message
      })
    };
    const RestForm = () => {
      setEmail("")
      setMessage("")
    };
    const response = fetch(`${config.url}/ContactMessages`, requestOptions)
      .then((res) => {
        if (res.status > 300) {
          alert('Please check your fields')
        }
        else {
          alert('Your Message has been successfully sent, a response will be sent to the email provided')
          RestForm()
        }
      })
    console.log(response);
  };
  return (

    <>
      {Navigation()}
      <Container>
        <Row>
        <br />
          <br />
          <br />
        </Row>
        <Row>
         
          <div style={{ textAlign: 'center' }}>
            <h1>DEEMI is a registered 501(c)(3) organization</h1>
          </div>
          <br />
          <br />
        </Row>
        <br />
        <div style={{ textAlign: 'center' }}>
          <h2>PO Box 268</h2>
          <h2>Orono</h2>
          <h2>ME 04473</h2>
        </div>
        <br />
        <Row>
          <br />
          <br />
          <div style={{ textAlign: 'center' }}>
            <Button variant="primary" onClick={handleShow} size="lg" >
              Contact DEEMI
            </Button>
          </div>
          <br />
          <br />
        </Row>
        <Row>
          <br />
          <br />
        </Row>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Contact Us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="name@example.com" onChange={(e) => setEmail(e.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} onChange={(e) => setMessage(e.target.value)} />
              </Form.Group>
              <Button variant="primary" type="submit" style={{ textAlign: 'center' }}>
                Submit
              </Button>
            </Form>

          </Modal.Body>

        </Modal>
        <Row>
    
        </Row>

      </Container>
    </>
  );
}

export default ContactUs;