
import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/esm/Container";

import Card from "react-bootstrap/Card";

function Board() {

      return (
        <>
        
        <Container>
          <Row>
            <Col>           
                <Card style={{ width: '18rem' }}>
                  <Card.Img variant="top" src="/img/Headshots/RichardBowie.png" />
                  <Card.Body>
                    <Card.Title>Richard Bowie M.S</Card.Title>
                    <Card.Text>
                      Board and Director 1991 to present.
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
            <Col>           
                <Card style={{ width: '18rem' }}>
                  <Card.Img variant="top" src="/img/Headshots/DanielFinck.png" />
                  <Card.Body>
                    <Card.Title>Daniel Finck</Card.Title>
                    <Card.Text>
                      Board 1991 to present.
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
            <Col>           
                <Card style={{ width: '18rem' }}>
                  <Card.Img variant="top" src="/img/Headshots/FabioCrichigno.png" />
                  <Card.Body>
                    <Card.Title>Fabio Crichigno Esq</Card.Title>
                    <Card.Text>
                      Board 1991 to present.
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
          
          <Col>           
                <Card style={{ width: '18rem' }}>
                  <Card.Img variant="top" src="/img/Headshots/DocBowie.png" />
                  <Card.Body>
                    <Card.Title>Robert Bowie M.D.</Card.Title>
                    <Card.Text>
                      Board 1991 to present.
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
            </Row>
          <Row>
            <br/>
          </Row>
          <Row>
            <Col>           
                <Card style={{ width: '18rem' }}>
                  <Card.Img variant="top" src="/img/Headshots/RodgerAvery.png" />
                  <Card.Body>
                    <Card.Title>Rodger Avery</Card.Title>
                    <Card.Text>
                      Board 2017 to present.
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
            <Col>           
                <Card style={{ width: '18rem' }}>
                  <Card.Img variant="top" src="/img/Headshots/LeonardCharbeneau.png" />
                  <Card.Body>
                    <Card.Title>Leonard Charbeneau</Card.Title>
                    <Card.Text>
                      Board 2018 to present.
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
         
           
            <Col>
            <Card style={{ width: '18rem' }}>
                  <Card.Img variant="top" src="/img/Headshots/MichaelChase.png" />
                  <Card.Body>
                    <Card.Title>Michael Chase</Card.Title>
                    <Card.Text>
                      Board 2018 to present.
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
            <Col></Col>
            </Row>
          <Row>
            <br/>
          </Row>
         
        </Container>
        </>
      );
    }
    



export default Board;