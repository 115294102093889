import React from 'react';
import { useState, useEffect } from 'react';
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup"
import config from '../../Constants'
import { NavLink } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
function NewVolunteers(token){

    var myauth = `Bearer ${token}`;
    const [data, setData] = useState([]);
    GetEvent();
     function  GetEvent(){  
        useEffect(() => {     
        let myurl = `${config.url}/Volunteers`;      
      
         fetch(myurl, {     
          method: 'GET',
          headers: {
            'Content-Type': 'application/json', 'Authorization': `${myauth}`
          },
          })
          .then((res) => res.json())
          .then((data) => setData(data)) 
      } ,[]);}
    function handleDelete(mess){
      fetch(`${config.url}/Volunteers/${mess.id}`, {
        method: 'PUT',
        
        headers: {
          'Content-Type': 'application/json', 'Authorization': `${myauth}`
        },
        body: JSON.stringify({
            id: mess.id,
            firstName: mess.firstName,
            lastName: mess.lastName,
            email: mess.email,
            contactNumber: mess.contactNumber,
            address1: mess.address1,
            address2: mess.address2,
            city: mess.city,
            usstate: mess.usstate,
            zip: mess.zip,
            active : 0,
            deleted :   new Date()
        })
      })};
      function handleAccept(mess){
        var bodymess = JSON.stringify({
            id: mess.id,
            firstName: mess.firstName,
            lastName: mess.lastName,
            email: mess.email,
            contactNumber: mess.contactNumber,
            address1: mess.address1,
            address2: mess.address2,
            city: mess.city,
            usstate: mess.usstate,
            zip: mess.zip,
            active : 1            
        })

     
        fetch(`${config.url}/Volunteers/${mess.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': `${myauth}`},
        body: bodymess
        })};
      

      var messages =  data.map(mess => {
      
     
      return(
        <>
        <Card key={mess.id} >                
        <Card.Body>
            <Card.Title>{mess.firstName} {mess.lastName}</Card.Title>
            <Card.Body>
                <Card.Text>
                    {mess.address1}  
                    {mess.address2} 
                    {mess.city} 
                    {mess.ustate} 
                    {mess.zip}                 
                </Card.Text>
            </Card.Body>
        </Card.Body>
            
        <ListGroup className="list-group-flush">
            <ListGroup.Item key={mess.email}>Email : {mess.email}</ListGroup.Item>
            <ListGroup.Item key={mess.contactNumber}>Contact Number : {mess.contactNumber}</ListGroup.Item>       
          
        </ListGroup>
        
        <Card.Footer> 
         <NavLink variant="danger" onClick={() => handleDelete(mess)}>Delete</NavLink>
         </Card.Footer>
         <Card.Footer> 
          <NavLink variant="danger" onClick={() => handleAccept(mess)}>Accept</NavLink>
        
         </Card.Footer>
       
        </Card>
        <br/>
        </>

        );})

        var nonews = <Card  ><Card.Body><Card.Title>No Volunteers</Card.Title></Card.Body> </Card>
      

    return(
    <>
    
    {data.length > 0 ?  messages : nonews }
        
     
    </>
    );
}

export default NewVolunteers;