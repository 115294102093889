import Navigation from '../Navigation';
import React from 'react';
import Container from "react-bootstrap/Container";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Events from "./Events";
import News from "./News";
import Searches from "./Searches";

function Activity() {

  return (
    <>
    {Navigation()}
    <Container>
    <Tabs
      defaultActiveKey="News"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey={"News"} title="News" key={"News"}>
        {News()}
      </Tab>
      <Tab eventKey={"Searches"} title="Searches" key={"Searches"}>
        {Searches()}
      </Tab>
      <Tab eventKey={"Events"} title="Events" key={"Events"}>
        {Events()}
      </Tab>
    </Tabs>
    </Container>
    </>
  );
}
export default Activity;