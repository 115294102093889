import Navigation from '../Navigation';
import React from 'react';
import Container from "react-bootstrap/Container";
import Col from 'react-bootstrap/Col';
import  Row  from 'react-bootstrap/Row';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../Loading";
import { useState, useEffect } from 'react';
import config from "../Constants"

function Portal() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = config.domain;//"dev-4lrfjaj1k5hkjenw.us.auth0.com";
  
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:current_user",
            audience: config.audience, //"https://dev-4lrfjaj1k5hkjenw.us.auth0.com/api/v2/",
            client_id: config.clientId, //"jfxaDoS4vEnCAlbbct8EIQUlCjCNC64s",
            redirect_uri: window.location.origin,
            domain: domain//"dev-4lrfjaj1k5hkjenw.us.auth0.com",
          },
        });
  
        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
  
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`      
        
          },
        });
  
        const { user_metadata } = await metadataResponse.json();
        
        setUserMetadata(user_metadata);
      } catch (e) {
        console.log(e.message);
      }
    };
  
    getUserMetadata();
  
  }, [getAccessTokenSilently, user?.sub]);
  return (
    <>
    
      {Navigation()}
      
      <Container>

        
      <div>
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>{user.email}</p>
        <h3>User Metadata</h3>
        {userMetadata ? (
          <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
        ) : (
          "No user metadata defined"
        )}
      </div>
      </Container>
      
  
       

    </>
  );
}
export default withAuthenticationRequired (Portal);