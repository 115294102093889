import Navigation from "../Navigation"
import React from 'react';

import Container from "react-bootstrap/esm/Container";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Board from "./Board";
import Staff from "./Staff";
function Members() {

      return (
        <>
        {Navigation()}
        <Container>
        <Tabs
          defaultActiveKey="Board"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Board" title="Board of Directors">
            {Board()}
          </Tab>
          <Tab eventKey={"Staff"} title="Staff Positions">
            {Staff()}
          </Tab>
        
        </Tabs>
        </Container>
        </>
      );
    }
    



export default Members;